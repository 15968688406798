<template>
    <div class="document" :data-val="file.type" :class="[{ selected: selected }, $mq]" @click="detailFile(file)">
        <div class="image" :class="{ hasPreview: file.preview }" :style="file.preview != null ? { backgroundImage: 'url(' + file.preview + ')' } : {}"></div>

        <div class="title">
            <div class="name">{{ file.name }}</div>
        </div>

        <div class="meta">
            <!-- <div class="update">
                <template v-if="!['portrait'].includes($mq)">{{ $t('library.last_update') }} </template> {{ (file.upload_date * 1000) | moment('DD/MM/YYYY') }}
            </div> -->
            <div v-if="file.highlight" class="highlight"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FileItem',
    props: ['file'],
    data() {
        return {
            selected: true
        }
    },
    created() {
        // this.updateSelected( true );
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    methods: {
        detailFile(file) {
            this.$router.push({ path: `/library/${file.id}` })
        }
    }
}
</script>

<style lang="scss" scoped>
.document {
    position: relative;
    // DEFAULT IMAGES IF TYPE = NULL
    .image {
        @include background($size: 65px, $color: $neutro-t60, $image: img('file_neutro.svg'));
    }
    .title {
        @include background($size: 18px, $image: img('file_neutro_s90.svg'));
    }

    // IMAGES BY TYPES
    &[data-val='1'] {
        .image {
            @include background($size: 70px, $color: $neutro-t60, $image: img('image_neutro.svg'));
        }
        .title {
            @include background($size: 20px, $image: img('image_neutro_s90.svg'));
        }
    }

    &[data-val='2'] {
        .image {
            @include background($size: 50px, $color: $neutro-t60, $image: img('video_neutro.svg'));
        }
        .title {
            @include background($size: 13px, $position: bottom, $image: img('video_neutro_s90.svg'));
        }
    }

    &[data-val='3'] {
        .image {
            @include background($size: 65px, $color: $neutro-t60, $image: img('file_neutro.svg'));
        }
        .title {
            @include background($size: 18px, $position: bottom, $image: img('file_neutro_s90.svg'));
        }
    }

    @include interaction();
    @include display-flex();
    @include flex-direction(column);
    @include align-items($val: flex-start);
    @include justify-content();
    @include border-radius(6px);
    float: left;
    width: 21.5%;
    margin: 1%;
    min-height: 150px;
    overflow: hidden;
    background-color: #fff;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    padding: 9px;

    @include bgHover(#fff);

    @media (max-width: 1200px) {
        width: 30%;
    }

    @media (max-width: 900px) {
        width: 47%;
        width: 48%;
    }

    @media (max-width: 350px) {
        width: 46%;
        width: 48%;
    }

    @media (max-width: 250px) {
        width: 45%;
        width: 48%;
    }

    .image {
        @include border-radius(3px);
        width: calc(100%);
        height: 120px;
        display: inline-block;

        &.hasPreview {
            @include background($size: cover, $color: $main-t90, $image: img('blank_document.svg'));
        }
    }

    .title {
        background-position: left center !important;
        @include display-flex();
        @include align-items();
        margin-top: 3px;
        height: 45px;
        width: 100%;

        .name {
            @include display-inline-flex();
            @include align-items();
            @include font-size(m);
            @include text-ellipsis($line: 2);
            line-height: 1.2; // PORFABOR NO TOCAR
            // text-indent: 3px;
            color: $neutro-s80;
            font-family: $text;
            width: 100%;
            height: auto;
            padding-left: 30px;
            margin-top: 3px;

            // &:before {
            //     display: inline-block;
            //     height: 14px;
            //     width: 14px;
            //     content: '';
            //     margin-right: 3px;
            // }
        }
    }

    .meta {
        // margin-top: 3px;
        width: 100%;

        .update {
            @include font-size(s);
            color: $neutro-s70;
            float: left;
            width: calc(100% - 15px);
            font-family: $conden-light;
        }

        .highlight {
            float: right;
            width: 15px;
            height: 15px;
            top: 12px;
            right: 12px;
            position: absolute;
            padding: 12px;
            @include background($image: img('star_main.svg'), $size: 20px, $color: #fff);
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.portrait.document {
    .title {
        .name {
            @include font-size(xs);
        }
        .meta {
            .update {
                @include font-size(xs);
            }
        }
    }
}
</style>
