<template>
    <div v-if="categories" class="column left scrollbar" :class="[$mq]">
        <div v-for="(item, index) in categories" :key="item.id" class="category">
            <template v-if="item.highlight && showHighLight">
                <div class="item highlight" :class="{ selected: item.id == selected && !search }" @click="selectCategory(item)">
                    <span class="color"></span>
                    <span class="titl">{{ item.name }}</span>
                </div>
            </template>
            <template v-else-if="!item.highlight">
                <div class="item" @click="selectCategory(item)" :class="[{ expanded: isExpanded(item) }, { parent: item.subcategories && Object.keys(item.subcategories).length > 0 }, { selected: item.id == selected }]" :style="selected == item.id ? { backgroundColor: item.color ? item.color : '#666' } : {}">
                    <span class="color" :style="{ backgroundColor: item.color ? item.color : '#000000' }"></span>
                    <span class="titl">{{ item.name }}</span>
                </div>
                <div class="contain" v-if="item.subcategories && Object.keys(item.subcategories).length > 0" v-show="expanded.indexOf(item.id) > -1">
                    <div v-for="(section, j) in item.subcategories" :key="section.id" class="item child" :class="{ selected: section.id == selected && !search }" @click="selectCategory(section)">
                        <span class="titl" :style="selected == section.id && !search ? { backgroundColor: item.color ? item.color : '#666' } : {}">{{ section.name }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ColumnLibrary',
    props: {
        gSelected: { type: [String, Number, Boolean, Object], default: false },
        search: { type: [String, Number, Boolean, Object], default: false },
        portraitSearchOpen: { type: [Boolean], default: false },
        showHighLight: { type: Boolean, default: true }
    },
    data() {
        return {
            expanded: [],
            selected: false
        }
    },
    computed: {
        categories() {
            var items = Object.values(this.$store.getters['library/getCategories'])

            items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

            return items
        }
    },
    methods: {
        isExpanded(item) {
            if (item && (item.selected || this.expanded.indexOf(item.id) > -1)) {
                return true
            } else {
                return false
            }
        },

        selectCategory(item) {
            // if (item && item.subcategories && Object.keys(item.subcategories).length > 0) {
            //     let pos = this.expanded.indexOf(item.id)
            //     if (pos > -1) {
            //         this.expanded.splice(pos, 1)
            //     } else {
            //         this.expanded.push(item.id)
            //     }
            // } else {
            if (this.$route.name != 'Library') {
                this.$router.push({ name: 'Library' })
                // this.$router.push({ path: `/library` });
            }
            if (item) {
                if (!item.parent_id) {
                    if (this.expanded.includes(item.id)) {
                        this.expanded = this.expanded.filter(e => e !== item.id)
                    } else this.expanded.push(item.id)
                }
                this.selected = item.id
                this.$emit('changeCategory', item)
            }
            // }
        }
    },
    created() {
        if (!this.gSelected && !this.showHighLight && this.categories.length > 1) {
            var cat = this.categories[1]
            this.selectCategory(cat.subcategories[Object.keys(cat.subcategories)[0]])
            this.selected = this.gSelected.id
        } else {
            if (this.gSelected) {
                if (this.gSelected.parent_id) {
                    this.selectCategory(this.gSelected)
                    this.expanded.push(this.gSelected.parent_id)
                } else {
                    this.selectCategory(this.gSelected)
                    this.expanded.push(this.gSelected.id)
                }
            }
        }
    },
    watch: {
        gSelected: function() {
            // if (this.selected === false) {
            if (this.gSelected && this.categories) {
                if (!this.isExpanded(this.categories.find(category => category.id == this.gSelected.parent_id))) {
                    this.selectCategory(this.categories.find(category => category.id == this.gSelected.parent_id))
                }
                this.selected = this.gSelected.id
            } else if (!this.gSelected && !this.showHighLight && this.categories.length > 1) {
                var cat = this.categories[1]
                this.selectCategory(cat.subcategories[Object.keys(cat.subcategories)[0]])
                this.selected = this.gSelected.id
            }
            // }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.column {
    @include border-radius($alex-app-padding);
    @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($neutro-s90, 0.2));
    margin-top: 20px;
    padding: 9px 0px 9px 9px;

    &.left {
        height: calc(100% - 20px) !important;
    }

    .category {
        $icon-size: 13px;

        margin: 9px 0;

        .item {
            @include border-radius(4px 0 0 4px);
            @include display-flex();
            @include align-items();
            padding: 0 0 0 6px;
            cursor: pointer;

            .color {
                @include border-radius(50%);
                display: inline-block;
                min-width: $icon-size;
                width: $icon-size;
                height: $icon-size;
            }

            .titl {
                @include border-radius(4px 0 0 4px);
                @include text-ellipsis($line: 2);
                @include display-inline-flex();
                @include align-items();
                @include font-size(m);
                font-family: $text-bold;
                color: $neutro-s90;
                min-height: 40px;
                height: auto;
                max-width: calc(100% - #{$icon-size});
                width: auto;
                padding: 6px 6px 6px 9px;
            }

            &.child {
                .titl {
                    @include interaction();
                    font-family: $text;
                    min-height: 40px;
                    width: 100%;
                    padding-left: 15px;
                    margin-left: $icon-size;
                    margin-bottom: 3px;

                    @include bgHover($inactive-t90);
                }
            }

            &.parent {
                @include interaction();

                .titl {
                    @include background($image: img('down_caret_neutro_s90.svg'), $size: 6px, $position: right center);
                    padding-right: 12px;
                }
                &.expanded {
                    .titl {
                        background-image: img('up_caret_neutro_s90.svg');
                    }
                }

                @include bgHover($inactive-t90);
            }
        }

        .item.highlight {
            .color {
                @include background($image: img('star_main.svg'), $size: 17px, $color: transparent);
                width: 17px;
                height: 17px;
            }
            .titl {
                width: calc(100% - 17px);
                padding-left: 6px;
            }

            &.selected {
                background-color: $main;

                .color {
                    background-image: img('star_ffffff.svg');
                }

                .titl {
                    color: #fff;
                }
                @include bgHover($main);
            }
        }

        .item.selected {
            &.child .titl {
                font-family: $text-medium;
                color: #fff;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#fullcontent {
    .column.left.portrait {
        .category {
            .item {
                border-radius: 3px;
                background-color: #fff;
                padding: 10px;
                cursor: pointer;

                .titl {
                    width: 100%;
                    background-size: 10px;
                    background-position: right 5px center;
                }

                &.highlight {
                    .titl {
                        @include background($image: img('right_neutro.svg'), $size: 10px, $position: right center);
                        padding-right: 12px;
                    }
                }

                &.parent {
                    .titl {
                        @include background($image: img('right_neutro.svg'), $size: 10px, $position: right center);
                        padding-right: 12px;
                    }
                }

                &.selected {
                    .titl {
                        color: $neutro-s90;
                    }
                }
                &.highlight.selected {
                    .color {
                        @include background($image: img('star_main.svg'), $size: 17px, $color: transparent);
                    }
                }
            }
        }
    }
}
</style>
