<template>
    <div :key="selectedCat.id" id="content" class="library" :class="[$mq]">
        <template v-if="['portrait'].includes($mq)">
            <!-- <div class="portrait-library-container"> -->
            <div class="header">
                <!-- ON HIGHLIGHT -->
                <template v-if="!selectedCat && !file">
                    <div class="icon list" :class="{ selected: !searchBar }" @click="displayCategories()"></div>
                    <!-- <div class="icon search" :class="{ selected: search }" @click="displaySearch()"></div> -->
                    <div class="icon search" @click="searchSwitch()" :class="{ selected: searchBar }"></div>
                </template>
                <!-- ON A CATEGORY -->
                <div v-else-if="!file" class="back category" @click="changeCategory(selectedCat.parent_id ? allCategories[selectedCat.parent_id] : false)">
                    <div class="ball" :class="{ highlight: selectedCat.name == 'Destacados' }" :style="{ backgroundColor: selectedCat.parent_id ? (allCategories[selectedCat.parent_id] ? allCategories[selectedCat.parent_id].color : '#273151') : allCategories[selectedCat.id] ? allCategories[selectedCat.id].color : '#273151' }"></div>
                    <div class="name">{{ selectedCat.name }}</div>
                </div>
                <!-- ON DETAIL OF A RESOURCE -->
                <div v-else-if="file" class="back file detail" @click="reset()">
                    <div class="meta">
                        <div class="name">{{ file.name }}</div>
                        <div class="updated">
                            <div class="update">
                                {{ $t('library.last_update') }} ‎<span class="date"> {{ (file.upload_date * 1000) | moment('DD/MM/YYYY') }}</span>
                            </div>
                            <div class="highlight" v-if="file.highlight"></div>
                        </div>
                    </div>
                    <button v-if="file.download" class="btn more" @click.stop="toggleOptions($event.target)">‎</button>
                    <div class="more-options">
                        <button class="btn download" @click.stop="download()">
                            <!-- {{ $t('menu_aux.action.download') }} -->
                        </button>
                    </div>
                </div>
            </div>
            <div class="search-container" v-if="(searchBar || search) && !selectedCat && !file">
                <input ref="searchBarLibrary" class="search" type="text" @input="searchBarInput($event)" :value="search" />
                <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
            </div>
            <!-- </div> -->
        </template>

        <template v-if="$route.params.id">
            <detail v-if="file" :file="file"></detail>
        </template>
        <template v-else-if="selectedCat || search">
            <div class="content scrollbar right" :class="[{ searching: searchBar }, { 'hide-scrollbar': ['portrait'].includes($mq) }]">
                <div class="contain">
                    <template v-if="!['portrait'].includes($mq)">
                        <div v-if="((selectedCat == false || selectedCat.id == 0) && showHighlightCat) || selectedCat.description || search" class="alex-header">
                            <div class="search-container">
                                <input ref="searchBarLibrary" class="search" type="text" @input="searchBarInput($event)" :value="search" />
                                <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
                            </div>
                            <div class="messages-container">
                                <div class="icon" :class="{ oops: search && files.length == 0 }"></div>
                                <template v-if="search">
                                    <div v-if="files.length > 0" class="message" v-html="getStringObject('library.search_results', { s1: files.length, s2: search })"></div>
                                    <div v-else class="message error" v-html="getStringObject('library.search_no_results', { s: search })"></div>
                                </template>
                                <template v-else>
                                    <div v-if="(selectedCat == false || selectedCat.id == 0) && showHighlightCat" class="message">{{ $t('library.alex_message_highlight') }}</div>
                                    <div v-else-if="selectedCat" class="message">{{ selectedCat.description }}</div>
                                </template>
                            </div>
                        </div>
                        <div v-else class="alex-header">
                            <div class="search-container">
                                <input ref="searchBarLibrary" class="search" type="text" @input="searchBarInput($event)" :value="search" />
                                <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="search">
                            <div class="alex-header">
                                <div v-if="files.length > 0" class="message" v-html="getStringObject('library.search_results', { s1: files.length, s2: search })"></div>
                                <div v-else class="message error" v-html="getStringObject('library.search_no_results', { s: search })"></div>
                            </div>
                        </template>
                    </template>
                    <template v-if="selectedCat && selectedCat.id != 0 && !selectedCat.parent_id && Object.keys(selectedCat.subcategories).length > 0">
                        <div class="subcategories">
                            <div v-for="(item, index) in selectedCat.subcategories" :key="item.id" class="subcategory" @click="changeCategory(item)">
                                <div class="name">{{ item.name }}</div>
                            </div>
                        </div>
                        <div class="separator"></div>
                    </template>
                    <div class="files">
                        <FileItem v-for="item in files" :key="item.id" :file="item" @openFile="checkShowPrintButton()"></FileItem>
                    </div>

                    <!-- <p  class="empty">{{ $t('library.empty_files') }}</p> -->
                </div>
                <EmptyPage v-if="files.length == 0 && !search && !(!selectedCat.parent_id && selectedCat.subcategories && Object.keys(selectedCat.subcategories).length > 0)" :title="$t('empty_pages.library_title')" :description="$t('empty_pages.library_description')"></EmptyPage>
            </div>
        </template>
        <column v-if="!loading && !((['portrait'].includes($mq) && (file || selectedCat)) || (['portrait'].includes($mq) && searchBar))" :showHighLight="showHighlightCat" :gSelected="selectedCat" @changeCategory="changeCategory"></column>
    </div>
</template>

<script>
import EmptyPage from '../components/layout/EmptyPage.vue'

import i18n from '@/i18n'
import detail from '@/components/domain/library/detail'
import column from '@/components/domain/library/column'
import FileItem from '@/components/domain/library/FileItem'

export default {
    name: 'Library',
    components: { detail, column, FileItem, EmptyPage },
    data() {
        return {
            selectedCat: false,
            showHighlightCat: true,
            loading: true,
            searchBar: false,
            search: '',
            timerSearch: false,
            interval: null
        }
    },
    computed: {
        files() {
            if (!this.loading) {
                if (this.search != '') {
                    return this.$store.getters['library/getFilesSearched'](this.search)
                } else if (this.selectedCat) {
                    if (this.selectedCat.id == 0) {
                        var files = Object.values(this.$store.getters['library/getHighlightFiles'])
                        return files.sort((a, b) => a.name.localeCompare(b.name))
                    } else {
                        var files = Object.values(this.$store.getters['library/getFiles'](this.selectedCat.id, this.selectedCat.parent_id))
                        return files.sort((a, b) => a.name.localeCompare(b.name))
                    }
                } else {
                    var files = Object.values(this.$store.getters['library/getHighlightFiles'])
                    // if (files.length == 0) {
                    //     return []
                    // }
                    this.showHighlightCat = true

                    return files.sort((a, b) => a.name.localeCompare(b.name))
                }
            }
            return false
        },
        file() {
            if (this.$route.params.id) {
                return this.$store.getters['library/getFile'](this.$route.params.id)
            }
            return false
        },
        categories() {
            var items = Object.values(this.$store.getters['library/getCategories'])

            items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

            return items
        },
        allCategories() {
            return this.$store.getters['library/getCategories']
        }
    },
    methods: {
        downloadIni(files) {
            var self = this
            log('downloadIni...', files)
            if (files.length > 0) {
                var file = files.pop()

                log('Downloading...', file)
                if (!self.$store.getters.getIsApp) {
                    var a = document.createElement('a')
                    a.setAttribute('href', file)
                    a.setAttribute('download', '')
                    a.setAttribute('target', '_blank')
                    a.click()

                    if (files.length == 0) {
                        clearInterval(self.interval)
                    }
                } else {
                    clearInterval(self.interval)
                    var fileName = file.replace(/^.*[\\\/]/, '')
                    var fileExtension = fileName.split('.').pop()

                    var fileTransfer = new FileTransfer()
                    fileTransfer.download(
                        file,
                        cordova.file.externalRootDirectory + 'Download/' + fileName,
                        function(entry) {
                            log('Successful download...')
                            log('download complete: ' + entry.toURL())
                            self.downloadIni(files)
                        },
                        function(error) {
                            log('download error source ' + error.source)
                            log('download error target ' + error.target)
                            log('upload error code' + error.code)
                            self.$snackbar.error({ duration: 5000, closeable: true, message: self.$t('library.download_error') })
                        },
                        null, // or, pass false
                        {}
                    )
                }
            } else {
                log(self.$t('library.downloaded'))
                self.$snackbar.success({ duration: 5000, closeable: true, message: self.$t('library.downloaded') })
            }
        },

        download() {
            var files = this.file.file.split('|')

            this.$snackbar.success({ duration: 2000, closeable: true, message: this.$t('library.downloading') })
            this.interval = setInterval(this.downloadIni, 1500, files)
        },

        toggleOptions(element) {
            // GET ALL "3 DOTS MORE" BUTTONS
            var divs = document.getElementsByClassName('btn more')
            for (var i = 0; i < divs.length; i++) {
                // GET RELATIVE FROM "3 DOT MORE" BUTTON THE "MORE-OPTIONS" DIV
                var moreOptions = divs[i].parentElement.getElementsByClassName('more-options')[0]
                // ON THE CLICKED BUTTON TOGGLE CLASS
                if (divs[i] === element) {
                    if (moreOptions) moreOptions.classList.toggle('active')
                }
                // ELSE CLOSE THE REST OF "MORE-OPTIONS" DIVS
                else {
                    moreOptions.classList.remove('active')
                }
            }
        },

        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
                if ((self.$mq == 'landscape' || self.$mq == 'desktop') && self.search == '') {
                    self.selectedCat = self.categories.length > 0 ? self.categories[0] : false
                }
            }, 800)
        },
        searchSwitch() {
            if (!this.searchBar) {
                this.searchBar = true
                this.search = ''
                this.$nextTick(() => {
                    this.$refs.searchBarLibrary.focus()
                })
            } else {
                this.searchBar = false
                this.search = ''
            }
        },
        cleanSearch() {
            this.search = ''
        },
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        },

        load() {
            var self = this
            this.$overlay.loading()
            this.$store.dispatch('library/loadFiles').then(function() {
                self.$bar.show()
                self.loading = false
                if (self.file) {
                    var section = self.$store.getters['library/getSection'](self.file.subcategory_id)
                    // self.selectedCat = self.categories ? self.categories[0] : false
                    self.changeCategory(section)

                    if (!['portrait'].includes(self.$mq)) {
                        self.$bar.addAction('back', {
                            callback: function() {
                                self.$router.push('/library')
                            },
                            label: self.$t('menu_aux.action.back')
                        })
                        self.$bar.hideAction('search')
                    }
                } else {
                    self.$bar.hideAction('back')
                    if (!['portrait'].includes(self.$mq)) {
                        self.$bar.addNav(
                            [
                                {
                                    label: self.$t('tools.home_title'),
                                    callback: function() {
                                        self.selectedCat = null
                                        self.$router.push('/library')
                                        self.$bar.addNav([{ label: self.$t('tools.home_title'), callback: function() {} }])
                                    }
                                }
                            ],
                            'breadcrumbs'
                        )
                    }
                }
                if (!['portrait'].includes(self.$mq)) {
                    self.selectedCat = self.categories.length > 0 ? self.categories[0] : false
                }
                self.$overlay.close()
            })
        },

        changeCategory(category) {
            if (!category) {
                this.searchBar = false
            }
            this.selectedCat = category
            this.search = ''
        },
        reset() {
            this.$bar.reset()
            this.$router.push('/library')
        },
        displayCategories() {
            this.search = ''
            this.searchBar = false
        },
        displaySearch() {
            var self = this
            self.$popup.input({
                title: self.$t('library.alex_search'),
                textSave: self.$t('menu_aux.action.search'),
                textCancel: self.$t('popup.default.text_cancel'),
                messageError: self.$t('library.empty_search_error'),
                inputVal: '',
                callSave: function(pSelf, store) {
                    pSelf.showingError = false
                    pSelf.inputVal = pSelf.inputVal.trim()

                    if (pSelf.inputVal != '' && pSelf.inputVal.length > 0) {
                        // self.changeCategory('')
                        self.search = pSelf.inputVal
                        self.$popup.close()
                    } else {
                        pSelf.showingError = true
                    }
                }
            })
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    watch: {
        $mq: function(newChange, oldChange) {
            if (newChange == 'landscape') {
                if (!this.selectedCat && !this.search) {
                    this.selectedCat = this.categories.length > 0 ? this.categories[0] : false
                }
            } else if (newChange == 'portrait') {
                if (this.search && this.search != '') {
                    this.searchBar = true
                    this.selectedCat = false
                }
            }
        }
    },
    created() {
        this.$bar.reset()
        this.load()
    },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.library {
    contain: content !important;

    .content {
        padding: 20px;

        .contain {
            width: 100%;
            height: 100%;
            @include display-flex();
            @include flex-direction(column);

            .alex-header {
                width: 100%;
                margin: 0 auto;
                @include display-flex();
                @include align-items();
                @include justify-content();
                @include flex-direction(column);
                margin-bottom: 15px;
                padding: 8px;

                .search-container {
                    position: relative;
                    top: 0px !important;
                    padding: 0px !important;
                }
                .messages-container {
                    @include display-flex();
                    @include align-items();
                    padding-top: 10px;

                    .icon {
                        @include background($image: img('alexhead_06.svg'), $size: 60px, $position: top center);
                        height: 60px;
                        width: 60px;
                        margin-right: 10px;
                        display: inline-block;

                        &.oops {
                            @include background($image: img('alexhead_03.svg'), $size: 60px, $position: top center);
                        }
                        &.search {
                            @include background($image: img('search_neutro.svg'), $size: 25px);
                            right: 10px;

                            &.selected {
                                @include background($image: img('search_light.svg'), $size: 25px);
                            }
                        }
                    }

                    .message {
                        @include font-size(m);
                        @include border-radius(6px);
                        background-color: $info-t40;
                        display: inline-block;
                        width: calc(100% - 60px - 10px);
                        padding: 10px;
                        font-family: $text;
                        color: $main-s70;

                        &.error {
                            background-color: $warning-t40;
                        }
                    }
                }
                .search-container {
                    position: relative;
                    width: 100%;
                    margin: 0;
                    top: 65px;

                    .search {
                        @include display-flex();
                        @include align-items(center);
                        @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                        @include border-radius(4px);
                        @include font-size(ml);
                        height: 45px;
                        width: 100%;
                        background-color: #fff;
                        padding: 0 60px 0 45px;
                        border-bottom: none;
                        font-family: $text;
                    }

                    .close {
                        @include border-radius(4px);
                        @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                        width: 33px;
                        height: 33px;
                        position: absolute;
                        top: 7px;
                        right: 10px;
                        background-color: $neutro-t50;
                        cursor: pointer;
                    }
                }
            }

            .empty {
                @include background($image: img('empty4.svg'), $size: 70px, $position: top center);
                margin-top: 60px;
                padding-top: 90px;
                width: 100%;
            }
        }

        .subcategories {
            @include display-flex();
            @include flex-wrap(wrap);
            gap: 13px 2%;
            width: 100%;

            .subcategory {
                @include interaction();
                @include display-flex();
                @include flex-direction(column);
                @include align-items(flex-start);
                @include justify-content();
                @include border-radius(6px);
                @include bgHover(#fff);
                @include background($image: img('folder.svg'), $size: 20px, $position: left 15px center);
                @include flex-wrap(nowrap);
                margin: 1%;
                float: left;
                width: 21.5%;
                height: 55px;
                overflow: hidden;
                background-color: #fff;
                padding: 9px;
                padding-left: 45px;
                cursor: pointer;

                @media (max-width: 1200px) {
                    width: 30%;
                }

                @media (max-width: 900px) {
                    width: 48%;
                }

                .name {
                    line-height: 18px;
                    font-family: $text-bold;
                    @include text-ellipsis($line: 2);
                    @include font-size(sm);
                }
            }

            .separator {
                @include display-flex();
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .files {
            @include display-flex();
            @include flex-wrap(wrap);
            gap: 13px 2%;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#fullcontent {
    #content.portrait {
        .column.left {
            width: 100%;
            max-width: 100%;
            margin: 0;
            background-color: initial;
            padding: 0 10px;
            box-shadow: none;
            border-radius: 0;
            height: calc(100% - 55px) !important;
        }

        .content.right {
            width: 100%;
            max-width: 100%;
            float: none !important;
            height: calc(100% - 75px);

            &.searching {
                margin-top: 10px;
                height: calc(100% - 120px - 10px) !important;
            }

            .contain {
                @include display-flex();
                @include flex-wrap(wrap);
                @include flex-direction(row);
                gap: 13px 2%;
                height: auto;

                .document {
                    margin: 0;
                }
                .files {
                    @include display-flex();
                    @include justify-content(space-between);
                    flex-wrap: wrap;
                    gap: 14px 2%;
                    width: 100%;
                }
            }

            .alex-header {
                width: 100%;
                margin-top: 25px;

                .message {
                    width: 100%;
                    @include font-size(sm);
                }
            }
        }

        .subcategories {
            @include justify-content(space-between);
            .subcategory {
                margin: 0;
                width: 48%;
                .name {
                    @include font-size(s);
                }
            }

            .separator {
                margin-bottom: 0;
            }
        }
    }
    .library {
        &.portrait {
            height: calc(100%) !important;
            overflow: hidden !important;

            .header {
                position: relative;
                height: 65px;
                width: 100%;
                // margin-bottom: 10px;
                padding: 10px;
                z-index: 10;
                // background-color: $default-bg-color;

                .icon {
                    @include background($image: img('list_neutro.svg'), $color: #fff, $size: 25px);
                    @include border-radius(4px);
                    height: 45px;
                    width: 45px;
                    cursor: pointer;
                    position: absolute;

                    &.selected {
                        background-color: $main-dark;
                    }

                    &.list {
                        left: 10px;

                        &.selected {
                            @include background($image: img('list_light.svg'), $size: 25px);
                        }
                    }
                    &.search {
                        @include background($image: img('search_neutro.svg'), $size: 25px);
                        right: 10px;

                        &.selected {
                            @include background($image: img('search_light.svg'), $size: 25px);
                        }
                    }
                }

                .category {
                    @include display-inline-flex();
                    @include align-items(center);
                    @include justify-content();

                    .ball {
                        @include display-inline-flex();
                        @include border-radius(100%);
                        width: 12px;
                        height: 12px;

                        &.highlight {
                            @include background($image: img('star_main.svg'), $size: 18px, $position: center -2px);
                            width: 18px;
                            height: 18px;
                        }
                    }
                    .name {
                        @include display-inline-flex();
                        @include text-ellipsis();
                        padding-left: 10px;
                        font-family: $text-bold;
                    }
                }

                .back {
                    // background-color: #fff;
                    @include interaction();
                    @include border-radius(4px);
                    @include justify-content(left);
                    height: 45px;
                    line-height: 45px;
                    width: 100%;
                    max-width: unset;
                    @include text-ellipsis();
                    font-family: $text-bold;
                    // padding: 0 10px 0 0;
                    // background-color: $main-dark;
                    color: #fff;

                    &:before {
                        @include background($image: img('left_dark.svg'), $color: #fff, $size: 20px);
                        width: 45px;
                        height: 45px;
                        display: inline-block;
                        content: ' ';
                        float: left;
                        margin-right: 10px;
                    }
                    &.file.detail {
                        @include display-inline-flex();
                        @include align-items(center);
                        color: $neutro-s80;
                        .meta {
                            @include display-inline-flex();
                            @include flex-direction(column);
                            width: calc(100% - 45px - 55px);

                            .name,
                            .updated,
                            .updated .update {
                                @include font-size(s);
                                line-height: 20px;
                                // height: 16px;
                                font-family: $text;
                            }

                            .name {
                                display: block;
                                @include text-ellipsis();
                                font-family: $text-bold;
                                overflow: hidden;
                            }

                            .updated {
                                @include display-flex();
                                .update {
                                    @include display-inline-flex();
                                    @include font-size(13px);
                                    @include text-ellipsis();
                                    color: $neutro-s60;

                                    .date {
                                        font-family: $text-bold;
                                        color: $neutro-s60;
                                    }
                                }

                                .highlight {
                                    @include display-inline-flex();
                                    @include background($size: 15px, $position: center center, $image: img('star_main.svg'));
                                    padding-left: 20px;
                                    margin-bottom: 1px;
                                    font-family: $conden;
                                    width: fit-content;
                                }
                            }
                        }

                        .btn {
                            @include font-size(16);
                            max-width: 250px;
                            height: 45px;
                            width: 45px;
                            cursor: pointer;

                            &.delete {
                                @include background($image: img('delete_ffffff.svg'), $size: 20px, $position: left 5px center);
                                background-color: $error;
                                color: $neutro-light;
                                padding-left: 30px;
                                height: 55px;
                                &:hover {
                                    background-color: $error-dark;
                                }
                            }
                            &.more {
                                @include background($image: img('more_vert_primary.svg'), $size: 20px, $position: center center);
                                background-color: $default-sec-color;
                                // width: 40px;
                                &:hover {
                                    // background-color: $neutro-t50;
                                }
                            }
                        }

                        .more-options {
                            border-radius: 4px;
                            background-clip: padding-box;
                            @include align-items();
                            position: absolute;
                            right: 10px;
                            bottom: -40px;
                            z-index: 20;
                            background-color: #fff;
                            display: none;
                            align-content: center;
                            height: fit-content;
                            padding: 3px;
                            height: auto;

                            &.active {
                                @include display-flex();
                                @include justify-content();
                                @include align-items();
                                @include flex-direction(column);
                            }

                            .btn {
                                @include display-flex();
                                @include justify-content();
                                @include align-items();
                                width: 100%;
                                height: 40px;
                                min-width: 40px;

                                &.download {
                                    @include background($image: img('download_fff.svg'), $size: 25px, $position: center center);
                                }
                            }
                        }
                    }
                }
            }
            .search-container {
                position: relative;
                width: 100%;
                margin: 0;
                padding: 0px 10px;

                .search {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 45px;
                    width: 100%;
                    background-color: #fff;
                    padding: 0 60px 0 45px;
                    border-bottom: none;
                    font-family: $text;
                }

                .close {
                    @include border-radius(4px);
                    @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    top: 7px;
                    right: 20px;
                    background-color: $neutro-t50;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
